@import '/src/styles/_media.sass';

.patients-search {
    position: relative;
    padding: var(--top-padding) var(--side-padding);

    &__add-btn {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-bottom: var(--top-padding); }

    &__search-input {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        border: 1px solid var(--extra-faded-color);
        border-radius: var(--border-radius-md);
        height: 40px;
        background: var(--background-color);

        &__icon {
            position: absolute;
            height: 40px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 50px;
            font-size: 1.2em;
            background: transparent;
            color: var(--faded-color); }

        &__control {
            flex: 1;
            border: 0;
            outline-color: var(--primary-color-glow);
            padding-left: 50px;
            font-size: 1.4em;
            background: transparent; } }

    &__container {
        background: var(--background-color);
        padding: 1em;
        border-radius: var(--border-radius);

        &__search {}


        &__results {

            &__item {
                display: flex;
                flex-direction: row;
                padding: 1em;
                border-bottom: 1px solid var(--super-faded-color);

                &--selected {
                    // box-shadow: 0 0 2px var(--foreground-color)
                    background: var(--primary-color-alt); }

                &__content {
                    flex: 1;

                    &__name {
                        font-size: 1.2em;
                        font-weight: bold; }

                    &__info {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        flex-wrap: wrap;

                        &__item {
                            color: var(--faded-color);
                            font-size: 0.8em;
                            margin-right: 1em;

                            span {
                                margin-left: 0.4em; } } } }

                &__actions {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    >*:not(:first-child) {
                        margin-left: 0.5em; } } } }

        &__not-found {
            text-align: center;
            margin: 1em 0;
            color: var(--faded-color); } }

    @include media("<=mobile") {
        padding: 1em 0.5em;

        &__container {
            padding: 1em 0em; } } }




