.clearance-status-icon {
    font-size: 1.2em;

    &__not-required {
        color: var(--faded-color); }
    &__pending {
        color: var(--tag-yellow); }
    &__requested {
        color: var(--tag-orange); }
    &__accepted {
        color: var(--success-color); }
    &__rejected {
        color: var(--danger-color); } }
