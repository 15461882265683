.popup {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 200;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &--show {
        display: flex; }

    &__overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: #00000055; }

    &__container {
        position: relative;
        display: flex;
        flex-direction: column;
        background: var(--background-color);
        padding: 2em;
        border-radius: var(--border-radius);
        overflow: hidden;
        width: 75%;
        max-width: 600px;
        z-index: 210;

        &__close-btn {
            position: absolute;
            top: 0.5em;
            right: 0.5em;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            font-size: 1.2em;
            color: var(--faded-color);
            cursor: pointer; }

        &__title {
            color: var(--primary-color);
            text-align: center;
            font-size: 1.3em;
            font-weight: bold; }

        &__subtitle {
            color: var(--faded-color);
            text-align: center; }

        &__body {
            margin: 1em 0;
            display: flex;
            flex-direction: column;

            >*:not(:last-child) {
                margin-bottom: 0.5em; }

            hr {
                width: 100%; }

            &__section-title {
                color: var(--faded-color);
                font-weight: bold;

                &--mg-top {
                    margin-top: 0.5em; } }

            &__subsection {
                background-color: var(--primary-color-alt);
                padding: 0.5em;
                border-radius: var(--border-radius);
                margin: 0.5em 0; } }

        &__actions {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between; }

        &__form {

            &__input {
                width: 100%;
                margin-bottom: 1em;

                &-file-wrapper {
                    position: relative;
                    width: 100%;
                    height: 6em;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    background: var(--super-faded-color);
                    border: 3px dashed var(--faded-color);
                    border-radius: var(--border-radius);
                    cursor: pointer;
                    transition: all 0.3s ease;

                    &--active {
                        border-color: var(--primary-color);
                        background: var(--primary-color-alt); }

                    &__text {
                        color: var(--faded-color);
                        font-size: 1.2em; }

                    &__old {
                        position: relative;
                        color: white;
                        font-size: 0.9em;
                        background: var(--info-color);
                        padding: 0.3em 0.5em;
                        border-radius: var(--border-radius-sm);
                        margin: 0.5em 0;

                        &::before {
                            position: absolute;
                            bottom: 70%;
                            right: 98%;
                            content: 'old';
                            padding: 0.2em 0.4em;
                            background: var(--faded-color);
                            border-radius: var(--border-radius-sm);
                            font-size: 0.7em; } }


                    input {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        width: 100%;
                        opacity: 0;
                        cursor: pointer; } }

                &__label {
                    color: var(--faded-color); }

                &__control {
                    width: 100%;
                    padding: 0.2em 0.4em; }

                &__error {
                    color: var(--danger-color);
                    font-size: 0.9em; }

                &--group {
                    flex: 1;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;

                    >*:not(:first-child) {
                        margin-left: 0.3em;
                        flex: 1; } }

                &--small {
                    width: 4em; }

                &--checkbox {
                    text-align: right; }

                &--date {
                    width: 100%;
                    padding: 0.4em 0.2em;
                    font-size: 1em; } }

            &__submit-btn {

                button {
                    width: 100%; } } }

        &__success-pane,
        &__failure-pane {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            background: #ffffffdd;

            &__icon {
                color: var(--success-color);
                font-size: 5em; }

            &__message {
                color: var(--faded-color);
                font-size: 1.2em;
                margin-top: 1em; }

            &__close-btn {
                margin-top: 1em; } }

        &__failure-pane {
            &__icon {
                color: var(--danger-color); }

            &__message2 {
                color: var(--faded-color);
                font-size: 1em;
                margin-top: 1em; } } } }




