.stat-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    padding: 1.5em;
    background: var(--background-color);
    border-radius: var(--border-radius);

    &__icon {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 0.8em;
        background: var(--primary-color-alt);
        border-radius: var(--border-radius);

        &__svg {
            font-size: 1.4em;
            color: var(--primary-color); } }

    &__info {
        margin-left: 1.5em;

        &__title {
            font-size: 0.8em;
            color: var(--faded-color);
            line-height: 1.6em;
            white-space: nowrap; }

        &__stat {
            font-weight: bold; } } }
