.add-event-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    padding: 15px 25px;
    color: var(--background-color);
    background-color: var(--primary-color);
    border-radius: var(--border-radius);
    cursor: pointer;
    transition: all 0.3s ease;

    &__icon {
        font-size: 0.7em; }

    &__text {
        margin-left: 1em;
        font-size: 1em; }

    &:hover {
        box-shadow: 0 0 10px var(--primary-color); } }
