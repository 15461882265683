@import '/src/styles/_media.sass';

.manage-event-page {
    // padding: var(--top-padding) var(--side-padding)

    &__cancel-reason {
        width: 100%;
        padding: var(--top-padding) var(--side-padding); }

    &__top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0 var(--side-padding);
        margin-top: var(--top-padding);


        &__left {
            display: flex;
            flex-direction: column;

            &__title {
                font-size: 1.2em; }

            &__subtitle {
                color: var(--faded-color);
                margin-bottom: 1em; }

            &__timestamp {
                color: var(--faded-color);

                svg {
                    min-width: 2em; } } }

        &__right {

            >* {
                margin-left: 0.5em; } } }

    &__nav {
        position: sticky;
        top: 0;
        z-index: 10;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-bottom: 1em;
        background: var(--primary-color-alt);
        padding: 1em 0;

        &__item {
            position: relative;
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-decoration: none;
            cursor: pointer;

            &:not(:last-child)::before {
                $barHeight: 5px;

                position: absolute;
                content: '';
                left: calc(50% + 0.8em);
                bottom: calc(1em - 2.5px);
                height: $barHeight;
                width: 100%;
                background: var(--faded-color); }

            &--done {
                &::before {
                    background: var(--primary-color) !important; }
                .manage-event-page__nav__item__number {
                    background: var(--primary-color); } }

            &__label {
                color: var(--faded-color);
                margin-bottom: 0.4em;
                white-space: nowrap; }

            &__number {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 2em;
                width: 2em;
                border-radius: 50%;
                background: var(--faded-color);
                color: var(--background-color); } } }

    &__content {

        &__pane {
            position: relative;
            background: var(--background-color);
            padding: 1em;
            border-radius: var(--border-radius);
            min-height: 10em;
            margin: 0 var(--side-padding);
            margin-bottom: 1em; } }

    &__actions {
        position: sticky;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 12;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background: var(--background-color);
        padding: 1em 1em;
        box-shadow: 0 0 10px #00000022;

        &__left,
        &__right {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;

            >* {
                margin-left: 0.5em; } }

        &__overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1;
            background: #00000022; }

        &__status-selector {
            position: relative;

            &__overlay {
                position: fixed;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 20; }

            &__close-btn {
                align-self: flex-end;
                cursor: pointer;
                margin: 0 !important; }

            &__btn-group {
                position: absolute;
                bottom: 150%;
                right: 0;
                z-index: 21;
                display: flex;
                flex-direction: column;
                align-items: stretch;
                transition: all 0.3s ease;
                box-shadow: 2px 4px 10px #00000055;
                border-radius: var(--border-radius);
                background: var(--background-color);
                padding: 1em;

                button {
                    width: 100%; }

                >* {
                    margin: 0.5em 0;
                    width: max-content; }

                &--hide {
                    opacity: 0;
                    pointer-events: none; } } } }

    &__pdf-popup {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 100;
        background-color: #00000044;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &__container {
            width: 80%;
            min-height: 600px;
            height: 90%;

            >* {
                height: 100%;
                width: 100%; }

            @include media('<=tablet') {
                width: 90%; } } } }


