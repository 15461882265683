@import '/src/styles/_media.sass';

.side-menu-collapsed {
    grid-template-columns: auto 4fr !important; }

.App {
    --side-padding: 50px;
    --top-padding: 20px;
    --header-height: 80px;
    --footer-height: 60px;

    position: relative;
    display: grid;
    grid-template-columns: 18em 4fr;
    grid-template-rows: var(--header-height) 1fr var(--footer-height);
    grid-template-areas: "side-menu header" "side-menu main" "side-menu footer";
    min-height: 100vh;
    max-height: 100vh;
    font-size: 15px;

    .content-container {
        grid-area: main;
        background-color: var(--primary-color-alt);
        overflow-y: auto; }

    .header {
        grid-area: header; }

    .footer {
        grid-area: footer; }

    .side-menu {
        grid-area: side-menu; }

    @include media('<=mobile') {
        --side-padding: 10px;
        --header-height: 60px;

        display: flex;
        flex-direction: column;
        min-height: 100vh;
        font-size: 12px;

        .content-container {
            flex: 1;
            margin-top: var(--header-height);
            display: flex;
            flex-direction: column; }

        .header {
            position: fixed;
            top: 0;
            left: 0;
            right: 0; }

        .side-menu {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;

            transform: translateX(-100%); } }

    @include media('>mobile', '<=tablet') {
        --side-padding: 10px;
        --header-height: 60px;
        font-size: 13px; }

    @include media('>tablet', '<=monitorHD') {
        font-size: 14px; }


    // CONTROLS
    .selector {
        padding: 0.2em 0.3em;
        border: 1px solid var(--selector-color);
        color: var(--selector-color);
        border-radius: 4px;
        outline: none; }

    .input-txt {
        padding: 0.3em 0.5em;
        border: 1px solid var(--faded-color);
        border-radius: var(--border-radius-sm);

        &:focus {
            outline: none !important;
            border: 1px solid var(--primary-color); }

        @include media("<=mobile") {
            padding: 0.2em;
            font-size: 0.8em; } }

    .btn {
        outline: none;
        border: 0;
        padding: 0.5em 1em;
        font-size: 0.8em;
        color: var(--bg-alt);
        border-radius: var(--border-radius-sm);
        cursor: pointer;
        transition: all 0.2s ease;

        &:disabled {
            cursor: not-allowed;
            filter: grayscale(0.8);

            &:hover {
                box-shadow: 0 0 0px var(--primary-color); } }

        &-lg {
            padding: 0.7em 1.4em;
            font-size: 1em; }

        &-primary {
            background-color: var(--primary-color);
            &:hover {
                box-shadow: 0 0 5px var(--primary-color); } }
        &-info {
            background-color: var(--info-color);
            &:hover {
                box-shadow: 0 0 5px var(--info-color); } }
        &-success {
            background-color: var(--success-color);
            &:hover {
                box-shadow: 0 0 5px var(--success-color); } }
        &-warning {
            background-color: var(--warning-color);
            &:hover {
                box-shadow: 0 0 5px var(--warning-color); } }
        &-danger {
            background-color: var(--danger-color);
            &:hover {
                box-shadow: 0 0 5px var(--danger-color); } }
        &-faded {
            background-color: var(--faded-color);
            &:hover {
                box-shadow: 0 0 5px var(--faded-color); } } }



    // Common Styles
    .page-title {
        display: flex;
        flex-direction: column;

        margin-bottom: 20px;

        .title-text {
            font-size: 1.2em;
            font-weight: bold;
            line-height: 2.5em; }

        .title-subtext {
            font-size: 0.9em;
            color: var(--faded-color); } }

    .primary-color {
        color: var(--primary-color); }

    .error-msg {
        color: var(--danger-color);
        font-size: 0.9em; }

    .small-note {
        font-size: 0.8em;
        font-style: italic;
        color: var(--faded-color); }

    .spinner {
        animation: spinning 1s linear infinite; }

    .clickable {
        cursor: pointer; }

    .hr {
        width: 100%;
        height: 1px;
        background: var(--super-faded-color);
        margin: 0.5em 0; }

    .flex-basis-100 {
        flex-basis: 100%; }

    .contained-label {
        $gaps: 0.5em;

        display: flex;
        flex-direction: row;
        align-items: center;
        padding: $gaps;
        background-color: var(--primary-color);
        border-radius: var(--border-radius-sm);
        font-size: 0.9em;

        &--info {
            background-color: var(--info-color); }
        &--success {
            background-color: var(--success-color); }
        &--warning {
            background-color: var(--warning-color); }
        &--danger {
            background-color: var(--danger-color); }

        &--xsm {
            font-size: 0.7em; }
        &--sm {
            font-size: 0.8em; }
        &--md {
            font-size: 0.9em; }
        &--lg {
            font-size: 1em; }

        &__label {
            color: white; }

        &__content {
            background: var(--background-color);
            border-radius: var(--border-radius-sm);
            padding: 0.2em 0.3em;
            margin-left: $gaps; } }

    .link {
        color: var(--secondary-color); }


    // Layout Helpers
    .mg-left {
        margin-left: var(--mg); }

    .mg-right {
        margin-right: var(--mg); }

    .flex-break {
        flex-basis: 100%; }

    .white-space-wrap {
        white-space: normal; }

    @include media("<=mobile") {

        .hide-for-mobile {
            display: none !important; }
        .show-for-mobile {
            display: block !important; } }

    @include media(">mobile") {

        .hide-for-mobile {
            display: block !important; }
        .show-for-mobile {
            display: none !important; } } }


@keyframes spinning {
    0% {
        transform: rotateZ(0deg); }
    100% {
        transform: rotateZ(360deg); } }
