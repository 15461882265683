$spreadY: 1.8em;
$spreadX: 1em;

.clearance-status-picker {
    position: relative;
    height: 1em;
    width: 1em;
    margin: 0 auto;
    font-size: 1.3em;

    &--active {

        .clearance-status-picker {

            &__overlay {
                opacity: 1;
                pointer-events: all; }
            &__loader,
            &__status,
            &__not-required,
            &__pending,
            &__requested,
            &__accepted,
            &__rejected {
                z-index: 21; } } }


    &__overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 20;
        opacity: 0;
        pointer-events: none; }


    &__loader {
        position: absolute;

        svg {
            color: var(--faded-color);
            animation: spinner 1s infinite linear; } }

    &__status {
        position: absolute;
        cursor: pointer;

        &--disabled {
            pointer-events: none;
            cursor: not-allowed; }

        &__not-required {
            color: var(--faded-color); }
        &__pending {
            color: var(--tag-yellow); }
        &__requested {
            color: var(--tag-orange); }
        &__accepted {
            color: var(--success-color); }
        &__rejected {
            color: var(--danger-color); } }

    &__pickers {
        position: absolute;
        top: 0;
        z-index: 21;
        transition: all 0.3s ease;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background: var(--background-color);
        padding: 0.2em 0.4em;
        border: 2px solid var(--primary-color);
        border-radius: var(--border-radius-sm);

        &--hide {
            transform: translateX(0) translateY(0);
            opacity: 0;
            pointer-events: none;
            width: 0; }

        &--show {
            $pickersWidth: $spreadX * 7;

            transform: translateX(-($pickersWidth / 2) + 0.5em) translateY(-$spreadY);
            opacity: 1;
            pointer-events: all;
            cursor: pointer;
            width: $spreadX * 7; } }

    &__not-required,
    &__pending,
    &__requested,
    &__accepted,
    &__rejected {
        // position: absolute
        // transition: all 0.3s ease

        // &--hide
        //     transform: translateX(0) translateY(0)
        //     opacity: 0
        //     pointer-events: none

        // &--show
        //     opacity: 1
        //     pointer-events: all
 }        //     cursor: pointer

    &__not-required {
        color: var(--faded-color);
        // &--show
        //     transform: translateX(0) translateY(-$spreadY)
        //     &:hover
 }        //         transform: translateX(0) translateY(-$spreadY) scale(1.2)
    &__pending {
        color: var(--tag-yellow);
        // &--show
        //     transform: translateX(-$spreadX * 1.5) translateY(-$spreadY)
        //     &:hover
 }        //         transform: translateX(-$spreadX * 1.5) translateY(-$spreadY) scale(1.2)
    &__requested {
        color: var(--tag-orange);
        // &--show
        //     transform: translateX($spreadX * 1.5) translateY(-$spreadY)
        //     &:hover
 }        //         transform: translateX($spreadX * 1.5) translateY(-$spreadY) scale(1.2)
    &__accepted {
        color: var(--success-color);
        // &--show
        //     transform: translateX($spreadX * 3) translateY(-$spreadY)
        //     &:hover
 }        //         transform: translateX($spreadX * 3) translateY(-$spreadY) scale(1.2)
    &__rejected {
        color: var(--danger-color);
        // &--show
        //     transform: translateX(-$spreadX * 3) translateY(-$spreadY)
        //     &:hover
 } }        //         transform: translateX(-$spreadX * 3) translateY(-$spreadY) scale(1.2)

@keyframes spinner {
    0% {
        transform: rotateZ(0deg); }
    100% {
        transform: rotateZ(360deg); } }
