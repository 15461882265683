.color-picker {
    position: relative;
    width: fit-content;

    &__chosen {
        height: 1em;
        width: 1em;
        border-radius: 50%;
        cursor: pointer; }

    &__selector {
        position: absolute;
        top: 0;
        left: 150%;
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        width: 12em;
        background: var(--foreground-color);
        border-radius: var(--border-radius-md);
        transition: all 0.3s ease;

        &--open {
            opacity: 1;
            pointer-events: all; }

        &--closed {
            opacity: 0;
            pointer-events: none; }

        &__color {
            height: 1em;
            width: 1em;
            margin: 1em;
            border-radius: 50%;
            cursor: pointer; } }

    &__loader {
        position: absolute;

        svg {
            color: var(--faded-color);
            animation: spinner 1s infinite linear; } } }

@keyframes spinner {
    0% {
        transform: rotateZ(0deg); }
    100% {
        transform: rotateZ(360deg); } }
