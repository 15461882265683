.sprite {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--background-color);
    transition: all 1s ease;

    &--show {
        opacity: 1;
        pointer-events: all; }

    &--hide {
        opacity: 0 !important;
        pointer-events: none !important; }

    &__logo {
        font-size: 4em;
        color: var(--primary-color);
        font-weight: bold;
        letter-spacing: 5px;
        animation: pulsate 2s ease infinite; } }

@keyframes pulsate {
    0% {
        opacity: 1; }
    10% {
        opacity: 0.7; }
    15% {
        opacity: 1; }
    20% {
        opacity: 0.7; }
    25% {
        opacity: 0.9; }
    100% {
        opacity: 1; } }
