@import '/src/styles/_media.sass';

.month-schedule {
    display: flex;
    flex-direction: column;

    &__calendar-wrapper {
        position: relative; }

    &__filters {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 1em;

        >* {
            margin-right: 1em; }

        &__filter {

            &--right {
                margin-left: auto !important; }

            &__label {
                font-size: 0.8em;
                margin-right: 5px;
                color: var(--faded-color); }

            &__input {
                font-size: 0.9em;

                select {
                    color: var(--selector-color);
                    border-color: var(--selector-color);
                    padding: 0.2em 0.3em;
                    outline: none; } } } }

    &__weekdays {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        align-content: flex-start;
        flex-wrap: wrap;
        margin-bottom: 10px;

        span {
            width: calc(100% / 7);
            font-size: 0.7em;

            &:last-child {
                color: var(--danger-color); } } }

    &__calendar {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        align-content: start;
        flex-wrap: wrap;

        &__filler-day {
            width: calc(100% / 7);
            min-height: 70px;
            min-height: 100px;
            background: var(--bg-alt); }


        &__day {
            position: relative;
            width: calc(100% / 7);
            min-height: 10em;
            border: 1px solid transparent;
            margin: -0.5px;
            cursor: pointer;

            &:hover {
                border: 1px solid var(--extra-faded-color); }

            &--selected {
                background: var(--primary-color-alt);
                border: 1px solid var(--primary-color); }

            &__number {
                font-size: 0.7em;
                padding: 5px;
                pointer-events: none;
                user-select: none; }

            &__events-count {
                display: none;
                flex-direction: row;
                flex-wrap: wrap;
                color: var(--faded-color);

                >* {
                    margin: 0.2em; } }

            &:nth-child(7n) {
                color: var(--danger-color); }

            &__event {
                display: flex;
                flex-direction: column;

                background-color: var(--primary-color);
                color: var(--bg-alt);
                border-bottom: 1px solid var(--foreground-color);
                margin: 2px 3px;
                padding: 1px 3px;

                &--danger {
                    background-color: var(--danger-color); }
                &--pending {
                    background-color: var(--faded-color); }
                &--boarding_slip_generated {
                    background-color: var(--tag-yellow);
                    color: black; }
                &--waiting_for_confirmation {
                    background-color: var(--tag-orange);
                    color: black; }
                &--confirmed {
                    background-color: var(--tag-green); }
                &--completed {
                    background-color: var(--tag-purple); }
                &--cancelled {
                    background-color: var(--danger-color); }

                &__danger {
                    margin-right: 3px; }

                &__time {
                    font-size: 0.7em;

                    &__separator {
                        margin: 0 3px; } }

                &__title {
                    font-size: 0.8em; } }

            @include media('<=tablet') {

                &__events-count {
                    display: flex; }
                &__event {
                    display: none; } } } } }
