.basic-multi-selector {
    position: relative;
    border-radius: var(--border-radius-sm);
    width: 100%;
    min-width: 200px;
    height: 2.5em;
    font-size: 1em;
    border: 1px solid var(--foreground-color);

    &__overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 12; }

    &__toggle {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 13;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0.4em 0.8em;
        color: var(--foreground-color);
        cursor: pointer; }

    &__options {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        z-index: 14;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        box-shadow: 4px 6px 10px #00000055; }

    &__option {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0.4em 0.8em;
        background: var(--super-faded-color);
        cursor: pointer;
        user-select: none;

        &:hover,
        &--highlight {
            background: var(--extra-faded-color); }

        &__checkbox {
            margin-right: 0.8em; }

        &__label {
            flex: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; } } }
