.profile-page {
    padding: var(--top-padding) var(--side-padding);

    &__top {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        margin-bottom: 2em;

        &__left {
            display: flex;
            flex-direction: column;

            &__title {
                font-size: 1.2em; }

            &__subtitle {
                color: var(--faded-color); } }

        &__right {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between; } }

    &__content {
        background: var(--background-color);
        padding: 1em;
        border-radius: var(--border-radius);

        &__info {
            margin-bottom: 1em;

            &__label {
                color: var(--faded-color);
                font-size: 1.2em;
                margin-bottom: 0.3em; } } } }




