.phone-number-input {
    position: relative;
    width: 100%;
    min-height: 1.6em;

    &__control {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        color: transparent !important;
        user-select: none;
        font-size: 1px; }

    &__formatted {
        position: absolute;
        top: 2px;
        left: 2px;
        z-index: 0;
        pointer-events: none;
        user-select: none;
        padding-left: 0.2em; } }
