@import '/src/styles/_media.sass';

.day-events-compact {

    &__events {}

    &__event {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0.5em;
        border-bottom: 1px solid var(--super-faded-color);

        &__time {
            position: relative;
            background: var(--extra-faded-color);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 4em;
            width: 4em;
            border-radius: 50%;
            overflow: hidden;

            &__overlap {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 2;
                background: var(--primary-color); }

            &__clock {
                height: 80%;
                width: 80%;
                padding: 0.5em;
                background: var(--background-color);
                border-radius: 50%;
                overflow: hidden;
                z-index: 3;

                &__top {
                    text-align: center;
                    font-size: 0.9em; }

                &__bottom {
                    text-align: center;
                    font-size: 0.8em; } } }

        &__patient {
            flex: 1;
            padding: 0 1em;

            &__name {
                font-size: 1.2em; }

            &__info {
                display: flex;
                flex-direction: column;

                &__item {
                    color: var(--faded-color);
                    font-size: 0.8em;

                    span {
                        margin-left: 0.5em; } } } }


        &__actions {
            display: flex;
            flex-direction: row;
            align-items: center;

            >* {
                margin-left: 0.5em; } } } }


