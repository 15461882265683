@import '/src/styles/_media.sass';

.reschedule-event-popup {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 20;

    &__container {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;

        &__overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: #00000055; }

        &__content {
            position: relative;
            background: var(--background-color);
            padding: 2em;
            z-index: 21;
            margin: 0 1.4em;
            border-radius: var(--border-radius);
            width: 75%;
            max-width: 600px;
            overflow: hidden;

            &__title {
                text-align: center;
                color: var(--primary-color);
                font-weight: bold;
                margin-bottom: 1.5em;
                font-size: 1.3em; }

            &__field {
                display: flex;
                flex-direction: row;
                align-items: baseline;
                justify-content: space-between;

                margin: 0.6em 0;

                &__label {
                    flex: 1;
                    color: var(--faded-color);
                    margin-right: 2em; }

                &__input {
                    flex: 1;

                    &--group {
                        flex: 1;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;

                        >*:not(:first-child) {
                            margin-left: 0.3em;
                            flex: 1; } }

                    &--small {
                        width: 4em; }

                    &--checkbox {
                        text-align: right; }

                    &--date {
                        width: 100%;
                        padding: 0.4em 0.2em;
                        font-size: 1em; } } }

            &__errors {
                color: var(--danger-color); }

            &__submit-btn {
                width: 100%;
                margin-top: 2em; }

            &__success-pane,
            &__failure-pane {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                background: #ffffffdd;

                &__icon {
                    color: var(--success-color);
                    font-size: 5em; }

                &__message {
                    color: var(--faded-color);
                    font-size: 1.2em;
                    margin-top: 1em; }

                &__close-btn {
                    margin-top: 1em; } }

            &__failure-pane {
                &__icon {
                    color: var(--danger-color); }

                &__message2 {
                    color: var(--faded-color);
                    font-size: 1em;
                    margin: 0 4em;
                    margin-top: 1em;
                    text-align: center; }

                &__intersections {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;
                    flex-wrap: wrap;
                    width: 100%;
                    color: var(--background-color);
                    margin-top: 1em;

                    >div {
                        background: var(--danger-color);
                        padding: 1em;
                        border-radius: var(--border-radius);
                        font-size: 0.9em;
                        margin: 0.4em; } } }



            @include media('<=mobile') {
                width: 90%;

                &__field {
                    flex-direction: column;

                    &__label {
                        margin-bottom: 0.3em; }

                    &__input {
                        width: 100%;

                        &--small {}

                        &--checkbox {
                            text-align: left; } } } } } } }

