.selector-search-hybrid {
    position: relative;
    margin-bottom: 4em;

    &__search {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 2;
        width: 90%;

        .dropdown-search__chosen {
            width: 111%; } }

    &__select {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%; }

    &__search,
    &__select {
        input,
        select {
            height: 2em; }

        input {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right: 0; }

        select {
            outline: none; } }

    &__element {
        flex: 1; }

    &__mode-switch {
        margin-right: 0.2em;

        &__icon {
            padding: 0.3em;
            background: var(--foreground-color);
            color: var(--background-color);
            border-radius: var(--border-radius-sm);
            font-size: 0.7em;
            cursor: pointer;
            user-select: none;
            min-width: 5em; } } }
