.event-blood-thinners {
    margin-top: 1em;

    &__title {
        font-size: 1.1em;
        color: var(--primary-color);
        font-weight: bold;
        margin-bottom: 1em; }

    &__empty {
        text-align: center;
        color: var(--faded-color);
        font-size: 0.9em;
        margin: 2em 0; }

    &__list {
        display: flex;
        flex-direction: column;
        margin-bottom: 1em;

        &__item {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 0.6em;
            background: var(--primary-color);
            border: 1px solid var(--faded-color);
            border-radius: var(--border-radius-sm);
            margin: 0.5em 0;
            font-size: 0.9em;

            &__title {
                color: white;
                width: 8em;
                text-align: center; }

            &__description {
                flex: 1;
                align-self: stretch;
                background: white;
                color: black;
                border-top-right-radius: var(--border-radius-sm);
                border-bottom-right-radius: var(--border-radius-sm);
                padding: 0.2em 0.6em; } } }

    &__add {
        display: flex;
        flex-direction: row;
        align-items: baseline;

        &__label {}

        &__selector {
            flex: 1;
            margin: 0 1em; } }

    &__send {
        width: max-content;
        display: flex;
        flex-direction: row;
        align-items: stretch;

        &__checkboxes {
            display: flex;
            flex-direction: row;
            align-items: center;
            border: 1px solid var(--primary-color);
            border-top-left-radius: var(--border-radius-sm);
            border-bottom-left-radius: var(--border-radius-sm);

            label {
                margin-right: 0.5em; } } } }
