#root {
    --border-radius-sm: 4px;
    --border-radius-md: 8px;
    --border-radius: 12px;
    --mg: 5px;
}

#root {
    --background-color: #ffffff;
    --bg-alt: #fafafb;
    --input-bg-color: #fffafd;
    --section-color: #e9e9e9;
    --header-color: #ffffff;
    --alt-foreground-color: #4d4d4d;
    --inverse-text-color: #ffffff;
    --selector-color: #a1a1a1;
    --faded-color: #7b7f8e;
    --extra-faded-color: #adb3c9;
    --xx-faded-color: #d4d4d4;
    --super-faded-color: #edeef5;
    --foreground-color: #444444;
    --primary-color: #0075ff;
    --primary-color-glow: #0077ff99;
    --primary-color-low: #004ba0;
    --primary-color-mid: #dcecff;
    --primary-color-alt: #e7f2ff;
    --secondary-color: #3bcfad;
    --secondary-color-alt: #f0fbf8;
    --third-color: #ffa63e;
    --third-color-faded: #ffe0bd;
    --third-color-alt: #fef6ee;
    --info-color: #5bc0de;
    --success-color: #22bb33;
    --success-color-faded: #afffb8;
    --warning-color: #f0ad4e;
    --danger-color: #bb2124;
    --danger-color-light: #ca3655;
    --danger-color-lighter: #ffb9c8;
    --danger-color-faded: #fc999b;
    --link-color: #2196f3;
    --tag-gray: #5a5a5a;
    --tag-orange: orange;
    --tag-yellow: yellow;
    --tag-yellow-faded: rgb(255, 255, 220);
    --tag-green: green;
    --tag-green-dark: rgb(0, 92, 0);
    --tag-purple: #7030a1;
    --tag-red: #bb2124;
    --login-bg-low: #60c4ef;
    --login-bg-high: #5087c7;
}

#root.dark{
    --background-color: #202225;
    --input-bg-color: #41434b;
    --section-color: #37383f;
    --header-color: #3e3e3e;
    --faded-color: #aaa;
    --foreground-color: #fffafd;
    --primary-color-back: #313b5a;
    --primary-color-mid: #1cabef;
    --primary-color-fore: #47f6e3;
    --secondary-color-one: #eec651;
    --secondary-color-two: #4dae67;
    --secondary-color-three: #885f40;
}