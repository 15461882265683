.event-scheduling {
    position: relative;

    &__title {
        font-size: 1.1em;
        color: var(--primary-color);
        font-weight: bold;
        margin-bottom: 1em; }

    &__actions {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        flex-direction: row;
        align-items: center;

        >* {
            &:not(:first-child) {
                margin-left: 0.5em; } } }

    &__auth-status {
        display: flex;
        flex-direction: row;
        align-items: stretch;

        &--no-actions {
            >* {
                cursor: initial;
                pointer-events: none; } }

        &__not-required,
        &__tbd,
        &__requested,
        &__approved,
        &__rejected {
            padding: 0.4em;
            font-size: 0.8em;
            font-weight: bold;
            color: var(--faded-color);
            border-bottom: 2px solid var(--faded-color);
            cursor: pointer; }

        &__not-required {
            &--active {
                border-color: var(--faded-color);
                background-color: var(--super-faded-color); } }
        &__tbd {
            &--active {
                border-color: var(--tag-yellow);
                background-color: var(--tag-yellow-faded); } }
        &__requested {
            &--active {
                border-color: var(--third-color);
                background-color: var(--third-color-faded); } }
        &__approved {
            &--active {
                border-color: var(--success-color);
                background-color: var(--success-color-faded); } }
        &__rejected {
            &--active {
                border-color: var(--danger-color);
                background-color: var(--danger-color-lighter); } } }

    &__pane-nav {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-top: 1em;

        &__btns {
            display: flex;
            flex-direction: row;
            align-items: center;

            >*:not(:first-child) {
                margin-left: 0.5em; } }

        &__step {
            color: var(--faded-color);
            font-size: 0.9em; } } }
