@import '/src/styles/_media.sass';

.detailed-event-card {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    background: var(--primary-color-alt);
    border-radius: var(--border-radius);
    padding: 1em;
    padding-right: 3em;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
        box-shadow: 0 0 15px var(--primary-color-alt); }

    &__caret {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        background: var(--primary-color);
        color: white;
        padding: 0.8rem;
        font-size: 0.8em; }

    &__left {
        flex: 3;
        display: flex;
        flex-direction: row;
        align-items: center;

        &__icon {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            background: var(--primary-color-mid);
            border-radius: var(--border-radius);
            color: var(--primary-color);
            padding: 1rem;
            font-size: 2em; }

        &__info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            margin-left: 2em;
            padding-right: 2em;

            &__title {
                font-weight: bold;
                color: var(--foreground-color); }

            &__description {
                font-size: 0.8em; }

            &__date {
                display: flex;
                flex-direction: row;
                align-items: center;

                color: var(--faded-color);
                font-size: 0.7em;
                margin-top: 5px;

                &__from {}

                &__to {} } } }

    &__actors {
        flex: 2;
        display: flex;
        flex-direction: column;
        align-items: baseline;
        justify-content: space-between;

        &__physician,
        &__patient,
        &__hospital {
            font-size: 0.9em;

            &__icon {
                font-size: 0.9em;
                color: var(--faded-color);
                margin-right: 5px; } } }

    // MOBILE
    @include media("<=mobile") {
        &__caret {
            padding: 0.6rem;
            font-size: 0.6em; } } }
