.dropdown-search {
    position: relative;
    margin-bottom: 2em;

    &--hide-selection {
        margin-bottom: 0; }

    &__input {
        position: relative;
        height: 100%;
        width: 100%;

        &__input {
            height: 100%;
            width: 100%;
            padding: 0.4em 0;
            padding-right: 4em;
            padding-left: 2em;
            border: 1px solid var(--faded-color);
            border-radius: var(--border-radius-sm);
            font-size: 1em; }

        &__icon {
            position: absolute;
            left: 0.7em;
            color: var(--faded-color);
            height: 100%; }

        &__manual-input-btn {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            height: 100%;
            width: 2.5em;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            background: var(--primary-color);
            color: white;
            padding: 0.5em 0;
            cursor: pointer;
            border-top-right-radius: var(--border-radius-sm);
            border-bottom-right-radius: var(--border-radius-sm);

            &--disabled {
                cursor: not-allowed;
                background: var(--faded-color); } } }



    &__dropdown {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        z-index: 3;

        display: flex;
        flex-direction: column;
        align-items: stretch;
        box-shadow: 5px 5px 5px #00000055;

        &__item {
            padding: 0.4em 0.8em;
            background: var(--super-faded-color);
            cursor: pointer;

            &:hover,
            &--highlight {
                background: var(--extra-faded-color); } } }

    &__chosen {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;

        background: var(--primary-color);
        color: var(--bg-alt);
        padding: 0.4em;
        border-radius: var(--border-radius-sm);
        font-size: 0.8em;
        margin-top: 0.4em; } }

