@import '/src/styles/_media.sass';

.alerts {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 10000;

    @include media('<=mobile') {
        left: 0; }

    &__list {
        display: flex;
        flex-direction: column;

        &__alert {
            $gaps: 30px;

            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 1em 0;
            margin: 0.5em;
            background: var(--primary-color);
            color: var(--background-color);
            border-radius: var(--border-radius);
            box-shadow: 5px 5px 7px #00000033;

            &--info {
                background: var(--info-color); }
            &--success {
                background: var(--success-color); }
            &--warning {
                background: var(--warning-color); }
            &--danger {
                background: var(--danger-color); }

            &__icon {
                margin: 0 $gaps;
                font-size: 1.4em; }

            &__message {
                flex: 1;
                text-align: left;
                font-size: 0.9em;
                margin-right: $gaps;
                max-width: 300px;
                user-select: none; }

            &__close {
                $margin: 0.5em;

                position: absolute;
                top: $margin;
                right: $margin + 0.125em;
                font-size: 0.9em;
                cursor: pointer;

                @include media('<=mobile') {
                    top: 0;
                    bottom: 0;
                    height: 100%;
                    font-size: 1.2em;
                    display: flex;
                    align-items: center;
                    justify-content: center; } } } } }

