@import '/src/styles/_media.sass';

.homepage {
    position: relative;
    display: flex;
    flex-direction: column;

    height: 100%;
    color: var(--foreground-color);
    padding: var(--top-padding) var(--side-padding);

    .top-right {
        position: absolute;
        top: var(--top-padding);
        right: var(--side-padding); }

    .homepage-grid {
        $gap: 2em;

        flex: 1;
        display: grid;
        grid-gap: $gap;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 80px 4fr 0fr;
        grid-template-areas: 'top top side' 'mid mid side' 'bottom-left bottom-right bottom-side';

        width: 100%;

        >* {
            background-color: var(--background-color);
            border-radius: var(--border-radius); }

        .grid__top {
            grid-area: top;
            display: flex;
            flex-direction: row;
            align-items: stretch;
            justify-content: space-between;
            background-color: transparent !important; }

        .grid__mid {
            grid-area: mid;
            padding: 1em;
            >* {
                height: 100%; } }

        .grid__bottom-left {
            grid-area: bottom-left; }

        .grid__bottom-right {
            grid-area: bottom-right; }

        .grid__side {
            grid-area: side; }

        .grid__bottom-side {
            grid-area: bottom-side; } }

    @include media("<=mobile") {
        .homepage-grid {
            display: flex;
            flex-direction: column;

            .grid__top {
                align-items: stretch;
                justify-content: center;
                flex-wrap: wrap;

                >* {
                    margin: 1em; } } } }

    @include media('>mobile', '<=tablet') {
        .homepage-grid {
            display: flex;
            flex-direction: column;

            .grid__top {
                align-items: stretch;
                justify-content: center;
                flex-wrap: wrap;

                >* {
                    margin: 1em; } } } }

    @include media('>tablet', '<=monitorHD') {
        .homepage-grid {
            grid-template-columns: 1fr 1.5fr;
            grid-template-rows: 50% 1fr;
            grid-template-areas: 'top side' 'mid mid';

            .grid__top {
                display: flex;
                flex-direction: column;
                align-items: stretch;
                justify-content: space-between;

                >* {
                    margin: 1em; } } } } }
