.day-event-card {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    padding: 0.3em 0.5em;
    border-left: 3px solid var(--primary-color);
    background-color: var(--bg-alt);
    border-radius: var(--border-radius-sm);
    transition: all 0.3s ease;
    font-size: 1.1em;

    &--danger {
        border-color: var(--danger-color); }
    &--pending {
        border-color: var(--faded-color); }
    &--boarding_slip_generated {
        border-color: var(--tag-yellow); }
    &--waiting_for_confirmation {
        border-color: var(--tag-orange); }
    &--confirmed {
        border-color: var(--tag-green); }
    &--completed {
        border-color: var(--tag-purple); }
    &--cancelled {
        border-color: var(--danger-color); }

    &__danger-icon {
        color: var(--danger-color); }

    &:not(:last-child) {
        margin-bottom: 0.5em; }

    &__info {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: space-around;

        &__time {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 0.9em;
            color: var(--faded-color);

            >* {
                margin: 0 0.2em; }
            >*:first-child {
                margin-left: 0; }

            &__title {
                font-weight: bold;
                color: var(--foreground-color); } }

        &__status {} }

    &__statuses {
        display: flex;
        flex-direction: column;
        font-size: 0.8em;
        color: var(--faded-color);
        padding: 0 1em;

        &__item {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 0.2em;

            &__text {
                margin-left: 0.2em; } } }

    &__add-note {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        padding: 0 0.5em;
        border-left: 1px solid var(--extra-faded-color);
        border-right: 1px solid var(--extra-faded-color);
        margin: 0 0.6em;

        &__form {
            flex: 1;
            display: flex;
            flex-direction: row;
            align-items: stretch;
            border-radius: var(--border-radius-sm);
            overflow: hidden;

            textarea {
                flex: 1;
                resize: none;
                border-top-left-radius: var(--border-radius-sm);
                border-bottom-left-radius: var(--border-radius-sm);
                border-right: 0;
                font-size: 0.8em; }

            button {
                background: var(--success-color);
                padding: 0 0.5em;
                font-size: 1em;
                border: 0;
                color: white;
                cursor: pointer;
                transition: all 0.3s ease;

                &:disabled {
                    background: var(--faded-color);
                    cursor: not-allowed; } } } }

    &__participants {
        margin-left: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 0.8em;
        color: var(--faded-color);
        width: 18em;

        &__item {
            margin-bottom: 0.2em;
            display: flex;
            flex-direction: row;
            align-items: center;

            &__text {
                margin-left: 0.2em;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis; } } }

    &__actions {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: space-around; } }
