@import '/src/styles/_media.sass';

.add-user-popup {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 20;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: #00000055; }

    &__container {
        position: relative;

        padding: 2em;
        border-radius: var(--border-radius);
        background: var(--background-color);
        min-width: 40%;

        &__title {
            text-align: center;
            color: var(--primary-color);
            font-weight: bold;
            margin-bottom: 0.5em;
            font-size: 1.3em; }
        &__subtitle {
            text-align: center;
            color: var(--faded-color);
            font-weight: bold;
            margin-bottom: 1.5em;
            font-size: 1em; }

        &__form {

            &__field {
                display: flex;
                flex-direction: column;
                align-items: baseline;

                margin: 0.6em 0;

                &__label {
                    flex: 1;
                    color: var(--faded-color);
                    margin-right: 2em;
                    width: 100%;
                    min-width: 13em; }

                &__input {
                    width: 100%;
                    padding: 0.2em; }

                select {
                    padding: 0.3em 0.2em; }

                &__error {
                    font-size: 0.9em;
                    color: var(--danger-color); } }

            &__submit-btn {
                width: 100%;
                margin-top: 1em; }

            &__spinner {
                margin-right: 0.5em;
                color: var(--background-color);
                animation: spin infinite 0.4s linear; } }

        &__success-pane,
        &__failure-pane {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            background: #ffffffdd;

            &__icon {
                color: var(--success-color);
                font-size: 5em; }

            &__message {
                color: var(--faded-color);
                font-size: 1.2em;
                margin-top: 1em; }

            &__close-btn {
                margin-top: 1em; } }

        &__failure-pane {
            &__icon {
                color: var(--danger-color); }

            &__message2 {
                color: var(--faded-color);
                font-size: 1em;
                margin-top: 1em; } }

        @include media('<=mobile') {
            width: 90%;

            &__form {
                &__field {
                    flex-direction: column;

                    &__label {
                        margin-bottom: 0.3em; }

                    &__input {
                        width: 100%;

                        &--small {}

                        &--checkbox {
                            text-align: left; } } } } } } }

@keyframes spin {
    0% {
        transform: rotateZ(0deg); }
    100% {
        transform: rotateZ(360deg); } }
