@import '/src/styles/_media.sass';

.form-sections {
    display: flex;
    flex-direction: row;

    &__flex {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        >* {
            flex: 1;

            &:not(:first-child) {
                margin-left: 0.5em; } } }

    &__left {
        flex: 1;
        padding-right: 0.5em; }
    &__right {
        flex: 1;
        padding-left: 0.5em;

        &--fl-2 {
            flex: 2; } }



    @include media('<=tablet') {
        flex-direction: column;

        &__left,
        &__right {
            flex: initial;
            padding: 0; } } }

.form-field {
    margin-bottom: 0.7em;

    &__label {
        color: var(--faded-color); }

    &__input {
        width: 100%;
        @include media('<=tablet') {
            width: 100%; } }

    &__input[type="checkbox"] {
        width: initial; }

    &__value {

        &--row {
            display: flex;
            flex-direction: row;
            align-items: center;

            >*:not(:first-child) {
                margin-left: 0.5em; } } }

    &__error {
        color: var(--danger-color); } }
