.event-notes {

    &__title {
        font-size: 1.1em;
        color: var(--primary-color);
        font-weight: bold;
        margin-bottom: 1em; }

    &__list {
        display: flex;
        flex-direction: column;
        margin-bottom: 1em;

        &__item {
            position: relative;
            border-radius: var(--border-radius);
            padding: 0.4em 0.6em;
            background: var(--bg-alt);
            margin: 0.5em 0;

            &__sender {
                font-size: 0.8em;
                margin-bottom: 0.4em; }

            &__note {
                flex: 1; }

            &__delete {
                position: absolute;
                top: 0.5em;
                right: 0.5em; } } }

    &__empty {
        text-align: center;
        width: 100%;
        font-size: 1.2em;
        margin: 1em 0;
        color: var(--faded-color); }

    &__add {
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        &__label {}

        &__input {
            flex: 1;
            margin: 0 1em;

            textarea {
                width: 100%; } } }

    &__pane-nav {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-top: 1em;

        &__btns {
            display: flex;
            flex-direction: row;
            align-items: center;

            >*:not(:first-child) {
                margin-left: 0.5em; } }

        &__step {
            color: var(--faded-color);
            font-size: 0.9em; } } }
