.physician-details-page {
    padding: var(--top-padding) var(--side-padding);

    &__top {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        margin-bottom: 2em; }

    &__container {
        padding: 1em;
        border-radius: var(--border-radius);
        background: var(--background-color);
        margin-bottom: 1.5em;

        &__top {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            margin-bottom: 2em;

            &__title {
                font-size: 1.2em;
                color: var(--faded-color); } } } }
