@import '/src/styles/_media.sass';

.event-confirm-reschedule-popup {

    &__container {
        padding: 0;
        max-width: 900px;
        display: flex;
        flex-direction: row;

        @include media('<=mobile') {
            flex-direction: column;
            width: 90%; }

        &__icon {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: var(--background-color);
            font-size: 2em;
            padding: 1em;

            &--info {
                background: var(--info-color); }
            &--success {
                background: var(--success-color); }
            &--warning {
                background: var(--warning-color); }
            &--danger {
                background: var(--danger-color); } }

        &__body {
            flex: 1;
            padding: 1em;

            &__top {
                font-size: 1.2em;
                font-weight: bold; }

            &__content {
                color: var(--faded-color);
                padding: 2em 0;

                &__title {
                    color: var(--foreground-color);
                    font-weight: bold; }

                &__input-group {

                    span {
                        margin: 0 0.5em; } }

                &__toggles {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    flex-wrap: wrap;
                    margin-top: 0.5em;

                    >* {
                        flex: 1;
                        margin-right: 0.5em; } }

                &__selection-buttons {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin: 0.5em 0;

                    >:not(:first-child) {
                        margin-left: 0.5em; } }

                &__coms-table {
                    width: 100%;
                    max-width: 100%;
                    table-layout: fixed;
                    overflow-wrap: break-word;


                    th,
                    td {
                        padding: 0.2em 0.4em; }

                    th {
                        background: var(--primary-color-alt);
                        color: black;
                        text-align: left;

                        &:first-child {
                            width: 40px; } }

                    td {
                        font-size: 0.8em;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;

                        &[center-col] {
                            text-align: center;

                            input {
                                max-width: 30px; } } } } }

            &__actions {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
 } } } }                // border-top: 1px solid var(--super-faded-color)
