@import '/src/styles/_media.sass';

.static-alert {
    $gaps: 30px;

    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5em 0;
    margin: 0.5em 0;
    background: var(--primary-color);
    color: var(--background-color);
    border-radius: var(--border-radius);

    &--info {
        background: var(--info-color); }
    &--success {
        background: var(--success-color); }
    &--warning {
        background: var(--warning-color); }
    &--danger {
        background: var(--danger-color); }
    &--danger-light {
        background: var(--danger-color-light); }

    &__icon {
        margin: 0 $gaps;
        font-size: 1.4em; }

    &__message {
        flex: 1;
        text-align: left;
        font-size: 0.9em;
        margin-right: $gaps; } }

