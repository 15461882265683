@import '/src/styles/_media.sass';

.event-filters {

    &__filters {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 1em;

        &__filter {
            margin: 0.2em 1em;
            margin-left: 0;

            &__label {
                font-size: 0.9em;
                color: var(--faded-color); }

            &__control {
                font-size: 0.8em; } }

        &__date-range-filter {
            position: relative;


            &__wrapper {
                position: fixed;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 50;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                &__overlay {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    z-index: 51;
                    background-color: #000000aa;

                    &--hide {
                        opacity: 0;
                        pointer-events: none; } }

                &__inner {
                    z-index: 52;

                    &__holder {} } } } } }
