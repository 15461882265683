@import '/src/styles/_media.sass';

.manage-users-page {
    position: relative;
    padding: var(--top-padding) var(--side-padding);

    &__add-btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: var(--top-padding); }

    &__container {
        background: var(--background-color);
        padding: 1em;
        border-radius: var(--border-radius);

        &__search {

            .search__top {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                padding: 10px;

                &__left {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    flex-wrap: wrap;

                    >*:not(:first-child) {
                        margin-left: 0.5em; }

                    &__sorting {
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        span {
                            margin-right: 0.5em; } } }

                &__right {

                    .search__results-count {
                        padding: 7px 14px;
                        background: var(--primary-color);
                        color: var(--bg-alt);
                        font-weight: bold;
                        font-size: 0.8em;
                        border-radius: var(--border-radius); } } }

            .search__bottom {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                padding: 10px;

                &__left {}

                &__right {} } }

        &__results {
            padding: 0 0.6em; } }

    @include media("<=mobile") {
        padding: 1em 0.5em;

        &__container {
            padding: 1em 0em; }

        .search__top {

            &__left {
                flex: 1;
                flex-direction: column;
                align-items: stretch;

                &__sorting {
                    margin-top: 1em;
                    justify-content: space-between; } } } } }


