.statistics-page {
    padding: 3em;
    height: 100%;

    >div {
        background: white;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: var(--border-radius);

        >div {
            &:first-child {
                font-size: 3em;
                font-weight: bold;
                color: var(--foreground-color);
                text-align: center; } } } }
