@import '/src/styles/_media.sass';

.patient-data {
    padding: 2em;

    &__top {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1em;

        &__title {
            font-size: 1em;
            margin-bottom: 1.4em; }

        &__actions {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            &__action {

                &:not(:first-child) {
                    margin-left: 0.5em; } } } }


    &__list {
        display: flex;
        flex-direction: column;

        >* {
            margin-bottom: 1em; } }

    &__container {
        padding: 1em;
        background: var(--background-color);
        border-radius: var(--border-radius); }

    &__details {
        display: flex;
        flex-direction: column;

        padding: 1em;
        background: var(--background-color);
        border-radius: var(--border-radius);

        &__section {
            display: flex;
            flex-direction: column;

            margin-bottom: 2em;
            overflow-x: auto;

            &--inner {
                margin: 0.3em;
                padding: 0.3em;
                border: 1px solid var(--super-faded-color);
                border-radius: var(--border-radius);
                font-size: 0.8em; }

            &__top {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                &__title {
                    font-size: 1.3em;
                    color: var(--faded-color);
                    margin-bottom: 0.5em; }

                &__actions {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;

                    &__action {
                        font-size: 0.9em;
                        color: var(--faded-color);
                        cursor: pointer;
                        margin: 0 0.2em; } } }


            &:not(:last-child) {
                padding-bottom: 2em;
                border-bottom: 1px solid var(--extra-faded-color); } }

        &__items {
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap; }

        &__item {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            padding: 0.5em 1em;
            font-size: 1em;
            background: var(--primary-color-alt);
            border-radius: var(--border-radius);
            margin: 0.3em;


            &__label {
                color: var(--foreground-color);
                font-weight: bold;
                margin-right: 0.6em; }

            &__value {
                color: var(--faded-color);
                margin-left: 2em; } }

        &__table {
            max-width: 100%;
            overflow-x: auto;

            td,
            th {
                padding: 0.4em 1em;
                white-space: nowrap; }

            th:first-child,
            td:first-child {
                padding-left: 0; }

            th {
                text-align: left;
                color: var(--foreground-color); }

            td {
                font-size: 0.9em; }

            @include media('<=mobile') {

                td,
                th {
                    padding: 0.2em 0.4em; } } } } }
