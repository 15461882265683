@import '/src/styles/_media.sass';

.patient-demographics {
    padding: 2em;

    &__top {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1em;

        &__title {
            font-size: 1em;
            margin-bottom: 1.4em; } }

    &__actions {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background: var(--background-color);
        box-shadow: 0 0 10px #00000022;
        padding: 1em var(--side-padding);

        &__left {
            >* {
                margin-right: 1em; } }

        &__right {
            >* {
                margin-left: 1em; } } }

    &__container {
        padding: 1em;
        background: var(--background-color);
        border-radius: var(--border-radius); } }

.form-sections {
    display: flex;
    flex-direction: row;

    &__title {
        font-size: 1.1em;
        color: var(--primary-color);
        font-weight: bold;
        margin-bottom: 1em; }

    &__flex {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        >* {
            flex: 1;

            &:not(:first-child) {
                margin-left: 0.5em; } } }

    &__left {
        flex: 1;
        padding-right: 0.5em; }
    &__right {
        flex: 1;
        padding-left: 0.5em; }



    @include media('<=tablet') {
        flex-direction: column;

        &__left,
        &__right {
            flex: initial;
            padding: 0; } } }

.form-field {
    margin-bottom: 0.7em;

    &__label {
        color: var(--faded-color); }

    &__input {
        width: 100%;
        @include media('<=tablet') {
            width: 100%; } }

    &__input[type="checkbox"] {
        width: initial; }

    &__value {}

    &__error {
        color: var(--danger-color); } }
