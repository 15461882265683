.simple-sort-picker {
    $toggleColor: var(--primary-color);

    margin-left: 0.5em;
    outline: none;
    border: 0;
    background: var(--faded-color);
    border-radius: var(--border-radius-sm);
    padding: 0.3em 0.6em;
    font-size: 0.8em;
    color: var(--bg-alt);
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
        box-shadow: 0 0 5px $toggleColor; }

    &--selected {
        background: $toggleColor; } }
