.simple-tag {
    padding: 0.2em 0.4em;
    border-radius: var(--border-radius-sm);
    color: white;
    font-weight: bold;

    &--primary {
        background: var(--primary-color); }
    &--gray {
        background: var(--tag-gray); }
    &--orange {
        background: var(--tag-orange);
        color: black; }
    &--yellow {
        background: var(--tag-yellow);
        color: black; }
    &--green {
        background: var(--tag-green); }
    &--green-dark {
        background: var(--tag-green-dark); }
    &--red {
        background: var(--tag-red); }
    &--purple {
        background: var(--tag-purple); }

    &--xlg {
        font-size: 1.2em; }
    &--lg {
        font-size: 1.1em; }
    &--md {
        font-size: 1em; }
    &--sm {
        font-size: 0.9em; }
    &--xsm {
        font-size: 0.8em; }

    &__wrapper {
        position: relative;
        display: inline;

        &__hover-text {
            position: absolute;
            bottom: 100%;
            left: 0;
            z-index: 30;
            margin-bottom: 3px;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0.2em;
            font-size: 1em;
            background: var(--foreground-color);
            color: var(--background-color);
            border-radius: var(--border-radius-sm);
            box-shadow: 2px 3px 5px #00000055;
            opacity: 0;
            pointer-events: none;
            transition: all 0.2s ease; }

        &:hover {
            .simple-tag__wrapper__hover-text {
                opacity: 1; } } } }

