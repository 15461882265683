.toggle {
    $height: 20px;
    $width: 40px;

    position: relative;
    height: $height;
    width: $width;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &__bar {
        width: 80%;
        height: 5px;
        background: var(--extra-faded-color);
        border-radius: var(--border-radius-sm);
        transition: all 0.2s ease; }

    &__circle {
        position: absolute;
        top: 0;
        left: 0;
        height: $height;
        width: $height;
        background: var(--faded-color);
        border-radius: 50%;
        transition: all 0.2s ease; }

    &--on {
        .toggle__bar {
            background: var(--primary-color-low); }

        .toggle__circle {
            transform: translateX($width - $height);
            background: var(--primary-color);
            box-shadow: 0 0 7px var(--primary-color); } } }
