.event-procedures {

    &__title {
        font-size: 1.1em;
        color: var(--primary-color);
        font-weight: bold;
        margin-bottom: 1em; }

    &__empty {
        text-align: center;
        color: var(--faded-color);
        font-size: 0.9em;
        margin: 2em 0; }

    &__list {
        display: flex;
        flex-direction: column;
        margin-bottom: 1em;

        &__item {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid var(--super-faded-color);
            padding: 0.4em 0.6em;

            &__code {
                padding: 0.4em;
                background: var(--faded-color);
                color: var(--background-color);
                border-radius: var(--border-radius-sm);
                margin-right: 0.5em;
                width: 6em;
                text-align: center;

                svg {
                    &:first-child {
                        color: white; } }

                input {
                    width: 2.9em;
                    font-size: 0.8em; } }

            &__text {
                flex: 1; }

            &__delete {} } }

    &__add {
        display: flex;
        flex-direction: row;
        align-items: baseline;

        &__label {}

        &__selector {
            flex: 1;
            margin: 0 1em; } }

    &__pane-nav {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-top: 1em;

        &__btns {
            display: flex;
            flex-direction: row;
            align-items: center;

            >*:not(:first-child) {
                margin-left: 0.5em; } }

        &__step {
            color: var(--faded-color);
            font-size: 0.9em; } } }
