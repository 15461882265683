.user-slip {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    background-color: var(--primary-color-alt);
    padding: 5px 10px;
    border-radius: var(--border-radius);
    cursor: pointer;

    .avatar {
        font-size: 1.5em; }

    .info {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-left: 10px;

        .username {
            font-size: 0.8em; }

        .role {
            font-size: 0.7em;
            color: var(--faded-color); } } }
