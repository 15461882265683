.async-prompt-popup {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 200;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: #00000055; }

    &__container {
        position: relative;
        display: flex;
        flex-direction: column;
        background: var(--background-color);
        padding: 1em;
        border-radius: var(--border-radius);
        overflow: hidden;
        width: 75%;
        max-width: 300px;
        z-index: 210;

        &__message {
            color: var(--foreground-color);
            text-align: center;
            font-size: 1em; }

        &__input {
            padding: 0.2em 0.4em;
            margin: 1em 0; }

        &__actions {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between; } }

    &__btn {
        outline: none;
        border: 0;
        padding: 0.5em 1em;
        font-size: 0.8em;
        color: var(--bg-alt);
        border-radius: var(--border-radius-sm);
        cursor: pointer;
        transition: all 0.2s ease;

        &:disabled {
            cursor: not-allowed;
            filter: grayscale(0.8); }

        &-lg {
            padding: 0.7em 1.4em;
            font-size: 1em; }

        &-primary {
            background-color: var(--primary-color); }
        &-info {
            background-color: var(--info-color); }
        &-success {
            background-color: var(--success-color); }
        &-warning {
            background-color: var(--warning-color); }
        &-danger {
            background-color: var(--danger-color); }
        &-faded {
            background-color: var(--faded-color); } } }


