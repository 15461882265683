.settings-pane {
    padding: 2em 5em;

    &__section {
        margin-bottom: 2em;

        &__title {
            color: var(--faded-color);
            font-weight: bold;
            font-size: 1em;
            margin-bottom: 1em; }

        &__body {
            padding: 1em;
            background: var(--background-color);
            border-radius: var(--border-radius);

            &__item {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                flex-wrap: wrap;

                &__label {}

                &__control {}

                input,
                select {
                    padding: 0.2em 0.4em; } } } } }


