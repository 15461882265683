.register-page {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 15;

    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background: var(--primary-color-alt);
    color: var(--foreground-color);
    height: 100%;

    &__container {
        display: flex;
        flex-direction: column;

        padding: 6em 2em;
        background: var(--background-color);
        border-radius: var(--border-radius);
        min-width: 300px;

        &__title {
            font-weight: bold;
            font-size: 1.2em; }

        &__subtitle {
            font-size: 0.8em;
            color: var(--faded-color);
            margin-bottom: 2em; }

        &__form {

            &__field {
                position: relative;
                height: 2em;
                width: 100%;

                &__icon {
                    position: absolute;
                    left: 0.5em;
                    z-index: 3;
                    height: 100%;
                    color: var(--faded-color); }

                &__input {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    z-index: 2;
                    padding-left: 2.5em;
                    width: 100%;
                    border: 0;
                    outline: none;
                    border-bottom: 1px solid var(--extra-faded-color);
                    transition: all 0.2s ease;

                    &:focus {
                        box-shadow: 0 0 10px #00000022; } } }

            &__submit-btn {
                align-self: flex-end;
                margin-top: 1em;
                border: 0;
                border-radius: var(--border-radius);
                outline: none;
                padding: 1em 2em;
                background: linear-gradient(135deg, var(--primary-color), var(--primary-color-low));
                color: var(--bg-alt);
                cursor: pointer;
                transition: all 0.2s ease;

                >*:not(:first-child) {
                    margin-left: 0.4em; }

                &:hover {
                    box-shadow: 0 0 10px var(--primary-color); }

                &:disabled {
                    filter: saturate(0.2);
                    cursor: not-allowed; } } }

        &__nav {
            align-self: center;
            margin-top: 1em;

            a {
                // text-decoration: none
                color: var(--link-color);
                font-size: 0.9em; } }



        .logo {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: var(--primary-color);

            &__image {
                height: 30px;
                width: 30px;
                margin-right: 10px; }

            &__text {
                font-weight: bold; } } }

    .register-form {
        display: flex;
        flex-direction: column;
        align-items: center;

        >* {
            margin-bottom: 10px; } } }



.spin {
    animation: 0.5s linear infinite spin; }

@keyframes spin {
    0% {
        transform: rotateZ(0deg); }
    100% {
        transform: rotateZ(-360deg); } }

