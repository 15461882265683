.loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 500;

    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background: #00000022;

    &--active {
        display: flex; }

    &__icon {
        font-size: 3em;
        color: var(--primary-color);
        animation: spin 1s infinite linear; } }

@keyframes spin {
    0% {
        transform: rotateZ(0deg); }
    100% {
        transform: rotateZ(360deg); } }

