.month-events {
    position: relative;

    &__mode-switch {
        position: absolute;
        top: 0.5em;
        right: 0.5em;
        z-index: 10; }

    &__calendar-container {
        position: relative;
        background: var(--background-color);
        padding: var(--top-padding) var(--side-padding);
        border-radius: var(--border-radius); }

    &__list-container {
        position: relative;
        display: flex;
        flex-direction: column;

        background: var(--background-color);
        padding: var(--top-padding) var(--side-padding);
        border-radius: var(--border-radius);
        margin-top: 20px;

        >* {
            margin: 10px 0; }

        &__mode-switch {
            position: absolute;
            top: 0.5em;
            right: 0.5em;
            margin: 0; }

        &__title {

            &__date {
                font-weight: bold;
                color: var(--primary-color); } }

        &__empty-text {
            text-align: center;
            color: var(--faded-color); } } }
