.week-calendar {
    min-width: 300px;

    &__calendar {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: space-between;

        .calendar__day {
            $sideMargin: 2px;

            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

            padding: 15px 10px;
            border-radius: var(--border-radius);
            cursor: pointer;
            transition: all 0.3s ease;

            &__name {
                color: var(--faded-color);
                font-size: 0.7em;
                font-weight: bold; }

            &__number {
                font-size: 1.1em;
                margin-top: 0.7em; }

            &:not(:first-child) {
                margin-left: $sideMargin; }

            &:not(:last-child) {
                margin-right: $sideMargin; }

            &:hover {
                background: var(--secondary-color-alt);

                .calendar__day__name {
                    color: var(--secondary-color); }

                .calendar__day__number {
                    color: var(--secondary-color); } }

            &--selected {
                background: var(--primary-color-alt);

                .calendar__day__name {
                    color: var(--primary-color); }

                .calendar__day__number {
                    color: var(--primary-color); } }

            &--today {
                border: 1px solid var(--primary-color-low); } } } }
