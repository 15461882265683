.styled-label {
    $gaps: 0.5em;

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: $gaps;
    background-color: var(--primary-color);
    border-radius: var(--border-radius-sm);
    font-size: 0.9em;

    &--primary {
        background-color: var(--primary-color); }
    &--info {
        background-color: var(--info-color); }
    &--success {
        background-color: var(--success-color); }
    &--warning {
        background-color: var(--warning-color); }
    &--danger {
        background-color: var(--danger-color); }

    &--xsm {
        font-size: 0.7em; }
    &--sm {
        font-size: 0.8em; }
    &--md {
        font-size: 0.9em; }
    &--lg {
        font-size: 1em; }

    &__label {
        color: white; }

    &__content {
        flex: 1;
        background: var(--background-color);
        border-radius: var(--border-radius-sm);
        padding: 0.2em 0.3em;
        margin-left: $gaps;
        text-align: center; } }
