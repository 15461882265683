.event-patient-communications {
    position: relative;

    &__title {
        font-size: 1.1em;
        color: var(--primary-color);
        font-weight: bold;
        margin-bottom: 1em; }

    &__actions {
        display: flex;
        flex-direction: row;
        align-items: center;

        >* {
            &:not(:first-child) {
                margin-left: 0.5em; } } }

    &__pane-nav {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-top: 1em;

        &__btns {
            display: flex;
            flex-direction: row;
            align-items: center;

            >*:not(:first-child) {
                margin-left: 0.5em; } }

        &__step {
            color: var(--faded-color);
            font-size: 0.9em; } }

    &__groups {
        display: flex;
        flex-direction: column;
        margin-bottom: 1em;

        &__group {
            position: relative;
            border-radius: var(--border-radius);
            background: var(--bg-alt);
            margin: 0.5em 0;

            &__top {
                display: flex;
                flex-direction: row;
                align-items: baseline;
                padding: 0.5em 1em;
                border-bottom: 1px solid var(--super-faded-color);

                input {
                    width: 250px; }

                &__title {
                    width: 300px;
                    max-width: 50%;
                    text-overflow: ellipsis;
                    font-weight: bold; }

                &__physician-label {
                    white-space: nowrap;
                    margin-right: 5px; }


                &__physician {
                    display: flex;
                    flex-direction: column;
                    align-items: stretch;
                    min-width: 200px; }

                &__physician-edit {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    font-size: 0.9em;
                    width: 6em;

                    input {
                        margin: 0 0.5em;
                        font-size: 0.9em; } }

                &__actions {
                    margin-left: 1em;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    >* {
                        margin-left: 1em; }

                    &--right {
                        margin-left: auto; } } } }

        &__empty-message {
            color: var(--faded-color);
            text-align: center;
            margin: 1em 0; } }



    &__items {
        width: 100%;
        display: flex;
        flex-direction: column;
        background: var(--background-color);

        &__item {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 0.4em 1em;

            >*:not(:first-child) {
                margin-left: 1em; }

            &--primary,
            &--secondary {
                &::before {
                    position: absolute;
                    left: 0;
                    top: 0.4em;
                    bottom: 0.4em;
                    content: '';
                    width: 3px;
                    border-radius: var(--border-radius);
                    background-color: var(--primary-color); } }

            &--primary::before {
                background-color: var(--primary-color); }
            &--secondary::before {
                background-color: var(--third-color); }


            &__status {
                display: flex;
                flex-direction: row;
                align-items: stretch;
                justify-content: space-between;

                &__not-required,
                &__pending,
                &__requested,
                &__accepted,
                &__rejected {
                    padding: 0.4em;
                    font-size: 0.8em;
                    font-weight: bold;
                    color: var(--faded-color);
                    border-bottom: 2px solid var(--faded-color);
                    cursor: pointer;
                    width: max-content;
                    color: var(--foreground-color);
                    user-select: none; }

                &__not-required {
                    &--active {
                        border-color: var(--faded-color);
                        background-color: var(--super-faded-color); } }
                &__pending {
                    &--active {
                        border-color: var(--tag-yellow);
                        background-color: var(--tag-yellow-faded); } }
                &__requested {
                    &--active {
                        border-color: var(--third-color);
                        background-color: var(--third-color-faded); } }
                &__accepted {
                    &--active {
                        border-color: var(--success-color);
                        background-color: var(--success-color-faded); } }
                &__rejected {
                    &--active {
                        border-color: var(--danger-color);
                        background-color: var(--danger-color-lighter); } } }

            &__description {
                flex: 1; }

            &__labels {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-left: auto;

                >* {
                    margin-left: 0.5em; } } }

        &__status-timestamp {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 0.5em 0;

            >* {
                margin-right: 1em; } } } }
