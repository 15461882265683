@import '/src/styles/_media.sass';

.event-status-picker {
    position: relative;

    &__overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 20;
        background-color: #000000aa; }

    &__close-btn {
        align-self: flex-end;
        cursor: pointer;
        margin: 0 !important; }

    &__btn-group__wrapper {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 31;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        &--hide {
            opacity: 0;
            pointer-events: none; } }

    &__btn-group {
        z-index: 32;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        transition: all 0.3s ease;
        box-shadow: 2px 4px 10px #00000055;
        border-radius: var(--border-radius);
        background: var(--background-color);
        padding: 1em;
        min-width: 300px;


        button {
            width: 100%; }

        >* {
            margin: 0.5em 0;
            width: max-content; } } }



