@import '/src/styles/_media.sass';

.day-events {
    position: relative;
    display: flex;
    flex-direction: column;

    &__slot {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        padding: 0.4em;
        border-bottom: 1px solid var(--super-faded-color);

        &:first-child {
            border-top: 1px solid var(--super-faded-color); }

        &__hour {
            width: 8em;
            color: var(--faded-color);
            user-select: none;

            @include media('<=tablet') {
                width: 4em; } }

        &__events {
            flex: 1;
            display: flex;
            flex-direction: column; } } }


