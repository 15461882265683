@import '/src/styles/_media.sass';

.table {
    position: relative;

    &__overlay {
        display: none; }

    &--loading {
        $loaderThickness: 3px;

        .table__overlay {
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 3;
            background: #00000011; }

        &::before {
            content: '';
            position: absolute;
            top: -$loaderThickness;
            left: 0;
            right: 0;
            z-index: 4;
            height: $loaderThickness;
            background: linear-gradient(135deg, var(--primary-color), var(--primary-color-alt)); }

        &::after {
            content: '';
            position: absolute;
            top: -$loaderThickness;
            left: 0;
            right: 0;
            z-index: 5;
            height: $loaderThickness;
            background: linear-gradient(135deg, var(--primary-color-alt), var(--primary-color));
            animation: fading 1.5s infinite linear; } }

    &__table-wrapper {
        overflow-x: auto;
        overflow-y: hidden; }

    table {
        width: 100%;
        border-collapse: collapse;

        tr {

            &:nth-child(2n+1) {
                background-color: var(--bg-alt); }

            th {
                position: relative;
                background: var(--primary-color-alt);
                text-align: left;
                font-size: 0.9em;
                vertical-align: baseline;

                .items-table {

                    &__th-label {
                        display: flex;
                        flex-direction: row;
                        align-items: baseline;
                        justify-content: space-between;

                        &__title {
                            display: block;
                            font-size: 0.9em; }

                        &__sort-picker {
                            margin-left: 0.3em; } }

                    &__filters {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                        margin-top: 0.2em;

                        &__input-txt {
                            flex: 1;
                            max-width: 80%;
                            font-size: 0.8em;

                            &--sm {
                                max-width: 3em; }

                            @include media('<=tablet') {
                                max-width: 5em; } } } } }


            td, th {
                border: 1px solid var(--super-faded-color);
                padding: 0.4em;
                white-space: nowrap; }

            th.actions-th {
                position: sticky;
                right: 0; }

            td {
                background: var(--background-color);
                font-size: 0.85em;

                a {
                    color: var(--link-color);
                    text-decoration: none; } }

            td.center-content {
                text-align: center; }

            td.table-action-btns {
                position: sticky;
                right: 0;
                z-index: 10;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-around;
                >* {
                    margin: 0 0.3em; } }

            td.no-data {
                text-align: center; } } }

    .empty {
        text-align: center;
        color: var(--foreground-color);
        padding: 100px 0; } }

@keyframes fading {
    0% {
        opacity: 1; }
    50% {
        opacity: 0; }
    100% {
        opacity: 1; } }
