.confirmation-popup {

    &__container {
        padding: 0;
        width: auto;
        max-width: 400px;
        display: flex;
        flex-direction: row;

        &__icon {
            flex: 2;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: var(--background-color);
            font-size: 2em;

            &--info {
                background: var(--info-color); }
            &--success {
                background: var(--success-color); }
            &--warning {
                background: var(--warning-color); }
            &--danger {
                background: var(--danger-color); } }

        &__body {
            flex: 3;
            padding: 1em;

            &__top {
                font-size: 1.2em;
 }                // text-align: center

            &__content {
                color: var(--faded-color);
                padding: 2em 0; }

            &__actions {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
 } } } }                // border-top: 1px solid var(--super-faded-color)
