.address-search {
    position: relative;
    height: 2em;

    &__input {
        $spacing: 2em;

        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
        background: var(--background-color);
        border: 1px solid var(--faded-color);

        &__control {
            flex: 1;
            height: 100%;
            border: 0;
            background: transparent;
            padding-left: $spacing; }

        &__icon {
            position: absolute;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: $spacing;
            color: var(--faded-color); } }

    &__suggestions {
        $suggestionHeight: 30px;

        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        border: 1px solid var(--faded-color);
        border-top: 0;
        max-height: $suggestionHeight * 5;

        &:empty {
            border: 0; }

        &__item {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: $suggestionHeight;
            background: var(--background-color);
            padding: 0.4em 1em;
            font-size: 0.9em;
            cursor: pointer;


            &:hover {
                background: var(--primary-color);
                color: var(--background-color); } } } }
