@import '/src/styles/_media.sass';

.side-menu {
    $leftSpace: 30px;

    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 30px 10px;
    background: var(--background-color);
    box-shadow: 5px 0 20px #88888822;
    z-index: 15;
    transition: all 0.3s ease;
    max-height: 100%;

    &--collapsed {

        .logo {
            &__text,
            &__subtext {
                display: none; } }

        .nav {

            &__section-title {
                display: none; }

            &__item {

                &__text,
                &__action {
                    display: none; } } } }

    &__close-btn {
        position: absolute;
        top: 1em;
        left: calc(100% - 10px);
        z-index: 2;
        height: 20px;
        width: 20px;
        background: var(--primary-color);
        color: var(--background-color);
        border-radius: 50%;
        outline: 0;
        border: 0;
        cursor: pointer;

        @include media('<=mobile') {
            display: none; } }

    .logo {
        display: flex;
        flex-direction: column;
        align-items: center;

        &__image {
            height: 50px;
            width: 50px;
            margin-bottom: 0.5em; }

        &__text {
            font-weight: bold;
            color: var(--primary-color);
            margin-bottom: 0.5em; }

        &__subtext {
            font-size: 0.9em;
            font-weight: bold;
            color: var(--faded-color); } }

    .nav {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        margin-top: 30px;
        overflow-y: auto;

        &__section-title {
            color: var(--faded-color);
            font-size: 0.7em;
            font-weight: bold;
            margin-left: $leftSpace;
            margin-bottom: 10px;
            margin-top: 20px; }

        &__item {
            $borderLeftWidth: 3px;

            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: baseline;
            padding: 15px $leftSpace;
            color: var(--faded-color);
            text-decoration: none;
            border-left: $borderLeftWidth solid transparent;
            transition: all 0.3s ease;

            &__icon {
                margin-right: 1em;
                width: 1.5em; }

            &__text {
                flex: 1; }

            &:hover {
                background: var(--primary-color-alt);
                border-left: $borderLeftWidth solid var(--primary-color);
                color: var(--primary-color); }

            &--selected {
                background: var(--primary-color-alt);
                border-left: $borderLeftWidth solid var(--primary-color);
                color: var(--primary-color); } } } }

.side-menu-closer {
    z-index: 14;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #00000055; }

