.events-page {
    padding: var(--top-padding) var(--side-padding);

    &__top {
        display: flex;
        flex-direction: column;
        margin-bottom: 2em;

        &__title {
            font-size: 1.2em; }

        &__subtitle {
            color: var(--faded-color); } }

    &__content {
        background: var(--background-color);
        padding: 1em;
        border-radius: var(--border-radius); } }


