.calendar-page {
    padding: var(--top-padding) var(--side-padding);

    &__calendar-container {
        background: var(--background-color);
        padding: var(--top-padding) var(--side-padding);
        border-radius: var(--border-radius); }

    &__list-container {
        display: flex;
        flex-direction: column;

        background: var(--background-color);
        padding: var(--top-padding) var(--side-padding);
        border-radius: var(--border-radius);
        margin-top: 20px;

        >* {
            margin: 10px 0; }

        &__title {

            &__date {
                font-weight: bold;
                color: var(--primary-color); } }

        &__empty-text {
            text-align: center;
            color: var(--faded-color); } } }
