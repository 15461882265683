@import '/src/styles/_media.sass';

.icon-button {
    border: 0;
    border-radius: var(--border-radius-sm);
    padding: 0.8em 1.6em;
    font-size: 1em;
    background: var(--primary-color);
    color: var(--background-color);
    cursor: pointer;
    transition: all 0.2s ease;
    user-select: none;
    min-width: fit-content;

    >span {
        margin-left: 0.3em;

        &:empty {
            margin: 0; } }

    &__text--hide-for-mobile {
        @include media('<=tablet') {
            display: none; } }


    &:hover {
        box-shadow: 2px 5px 5px #00000033;
        transform: translateY(-1px); }

    &--loading {
        filter: saturate(0.2);
        cursor: not-allowed;
        pointer-events: none;

        svg {
            animation: spin 0.6s linear infinite; } }

    &--icon-right {
        >span {
            margin-right: 0.3em; } }

    &:disabled {
        background: var(--faded-color);
        cursor: not-allowed;

        &:hover {
            box-shadow: 0 0 0 var(--primary-color); } }


    &-xsm {
        padding: 0.3em 0.6em;
        font-size: 0.7em; }
    &-sm {
        padding: 0.4em 0.8em;
        font-size: 0.8em; }
    &-md {
        padding: 0.6em 1.2em;
        font-size: 0.9em; }
    &-lg {
        padding: 0.8em 1.6em;
        font-size: 1em; }
    &-xlg {
        padding: 1em 2em;
        font-size: 1.1em; }

    &-success {
        background: var(--success-color); }
    &-info {
        background: var(--info-color); }
    &-warning {
        background: var(--warning-color); }
    &-danger {
        background: var(--danger-color); }
    &-faded {
        background: var(--faded-color); }
    &-purple {
        background: var(--tag-purple); } }

@keyframes spin {
    0% {
        transform: rotateZ(0deg); }
    100% {
        transform: rotateZ(360deg); } }
