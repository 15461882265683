.editable-field {

    &__text {

        &--clickable {
            cursor: pointer; } }

    &__edit-mode {
        display: flex;
        flex-direction: row;
        align-items: center;

        &__cancel,
        &__save {
            margin: 0 0.3em;
            cursor: pointer; }

        &__cancel {
            color: var(--faded-color);
            margin-right: 0.4em; }

        &__save {
            color: var(--success-color);
            margin-left: 0.4em; } } }
