.manage-user-page {
    padding: var(--top-padding) var(--side-padding);

    &__top {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 2em; }

    &__section {
        padding: 1em;
        border-radius: var(--border-radius);
        background: var(--background-color);
        margin-bottom: 1em;

        &__title {
            font-size: 1.2em;
            color: var(--faded-color);
            margin-bottom: 1em; }

        &__content {

            &__2pan {
                $gaps: 1em;

                display: flex;
                flex-direction: row;
                align-items: stretch;
                width: 100%;

                &__left {
                    flex: 1;
                    padding-right: $gaps; }


                &__right {
                    flex: 1;
                    padding-left: $gaps;
                    border-left: 1px solid var(--super-faded-color);
                    display: flex;
                    flex-direction: column; } } } }

    &__list {
        display: flex;
        flex-direction: column;
        align-items: stretch;

        &__actions {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 0.5em;

            >* {
                flex: 1;

                &:not(:first-child) {
                    margin-left: 0.5em; } } }

        &__item {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin: 0.4em 0;

            &__label {
                flex: 2;
                font-weight: bold; }

            &__control {
                flex: 3; } } }

    &__facilities {}

    &__facility {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0.3em 0; }

    &__add-facility {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: space-between;
        margin-top: auto;

        >*:not(:first-child) {
            margin-left: 1em; } } }

