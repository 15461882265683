@import '/src/styles/_media.sass';

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px var(--side-padding);
    height: var(--header-height);
    background: var(--background-color);

    &__title {
        padding: 0.2em 0.6em;
        background: var(--primary-color);
        color: var(--background-color);
        font-size: 1.2em;
        border-radius: var(--border-radius-sm);
        min-width: 10em;
        text-align: center; }

    &__user-slip {
        margin-left: auto; }

    &__burger {
        cursor: pointer;

        &__icon {
            color: var(--faded-color);
            font-size: 1.8em; } }

    &__logo {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: baseline;
        color: var(--primary-color);

        &__image {
            $logoSize: 2em;

            height: $logoSize;
            width: $logoSize;
            margin-right: 10px; }

        &__text {
            font-weight: bold; } }

    @include media('<=mobile') {
        &__title {
            margin-left: 1em; } }

    @include media('>mobile') {
        &__burger,
        &__logo {
            display: none; } } }



