.history-page {
    padding: var(--top-padding) var(--side-padding);

    &__top {
        display: flex;
        flex-direction: column;
        margin-bottom: 2em;

        &__title {
            font-size: 1.2em; }

        &__subtitle {
            color: var(--faded-color); } }

    &__content {
        background: var(--background-color);
        padding: 1em;
        border-radius: var(--border-radius);

        &__filters {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            margin-bottom: 1em;

            &__filter {
                margin: 0.2em 1em;
                margin-left: 0;

                &__label {
                    font-size: 0.9em;
                    color: var(--faded-color); }

                &__control {
                    font-size: 0.8em; } }

            &__date-range-filter {
                position: relative;


                &__wrapper {
                    position: fixed;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    z-index: 50;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;

                    &__overlay {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        z-index: 51;
                        background-color: #000000aa;

                        &--hide {
                            opacity: 0;
                            pointer-events: none; } }

                    &__inner {
                        z-index: 52;

                        &__holder {} } } } }

        &__list {
            display: flex;
            flex-direction: column;

            &__item {
                display: flex;
                flex-direction: row;
                align-items: stretch;
                padding: 0.5em 1em;
                padding-left: 0;
                border-left: 3px solid var(--primary-color);
                margin: 0.5em 0;
                background: var(--bg-alt);

                &--info {
                    border-color: var(--info-color); }
                &--success {
                    border-color: var(--success-color); }
                &--warning {
                    border-color: var(--warning-color); }
                &--danger {
                    border-color: var(--danger-color); }

                &__icon {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    font-size: 2.5em;
                    padding: 0em 0.8em;

                    &--info {
                        color: var(--info-color); }
                    &--success {
                        color: var(--success-color); }
                    &--warning {
                        color: var(--warning-color); }
                    &--danger {
                        color: var(--danger-color); } }

                &__content {
                    display: flex;
                    flex-direction: column;

                    &__title {
                        font-size: 1.1em;
                        font-weight: bold; }

                    &__user {
                        color: var(--faded-color);
                        font-size: 0.8em; }

                    &__date {
                        color: var(--faded-color);
                        font-size: 0.8em;
                        font-style: italic;
                        margin-top: auto; }

                    &__event {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        flex-wrap: wrap;

                        &__detail {
                            margin-right: 1em;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            color: var(--faded-color);
                            font-size: 0.9em;

                            span {
                                margin-left: 0.5em; } } } } } }

        &__empty {
            font-size: 1.5em;
            color: var(--faded-color);
            margin-bottom: 1em; }

        &__controls {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-top: 1em;

            &__control {
                margin: 0 1em;
                padding: 0.4em 1em;
                background: var(--primary-color);
                color: var(--background-color);
                border-radius: var(--border-radius-sm);
                cursor: pointer;
                user-select: none;
                font-size: 0.9em;

                &--disabled {
                    cursor: not-allowed;
                    filter: saturate(0.2); } } } } }

