@import '/src/styles/_media.sass';

.settings-page {
    display: flex;
    flex-direction: row;
    height: 100%;

    &__nav {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: stretch;

        background: var(--primary-color);
        padding: 2em 0em;

        &__close-btn {
            position: absolute;
            top: 1em;
            left: calc(100% - 10px);
            z-index: 2;
            height: 20px;
            width: 20px;
            background: var(--background-color);
            color: var(--primary-color);
            border-radius: 50%;
            outline: 0;
            border: 0;
            cursor: pointer;

            @include media('<=tablet') {
                display: none; } }

        &--closed {

            .settings-page__nav__item {
                padding: 0.8em 1.5em;

                &__label {
                    display: none; } } }

        &__item {
            display: flex;
            flex-direction: row;
            align-items: center;

            padding: 0.8em 2.5em;
            color: var(--bg-alt);
            text-decoration: none;

            &__icon {
                min-width: 1.5em; }

            &__label {
                margin-left: 1em; }

            &:hover {
                background-color: var(--primary-color-low); }

            &--selected {
                background-color: var(--primary-color-low); } } }

    &__content {
        flex: 1;
        overflow-y: auto; }

    @include media('<=tablet') {
        flex-direction: column;

        &__nav {
            flex-direction: row;
            overflow-x: auto;
            padding: 0;

            &__item {
                padding: 1.2em 2.5em;

                &__text {}
                white-space: nowrap; } } } }
