@import '/src/styles/_media.sass';

.login-page {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 21;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--foreground-color);
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    &__bg-grad {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 20;
        background: linear-gradient(135deg, #0d1a92, #3442be, #5087c7, #60c4ef, #7ba5ff, #7bf0ff, #a9eff8);
        background-size: 450% 100%;
        animation: animate_gradient 35s linear infinite;
        opacity: 0.6; }

    &__container {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        border: 4px solid white;
        border-radius: var(--border-radius);
        min-width: 600px;
        z-index: 21;

        @include media('<=mobile') {
            flex-direction: column;
            min-width: 90%;
            width: 90%; }

        &__left {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: baseline;
            padding: 2em;
            background: linear-gradient(135deg, var(--login-bg-high), var(--login-bg-low));
            border-top-left-radius: var(--border-radius);
            border-bottom-left-radius: var(--border-radius);
            color: white;

            @include media('<=mobile') {
                border-radius: 0;
                border-top-left-radius: var(--border-radius);
                border-top-right-radius: var(--border-radius); }

            &__row {
                align-self: stretch;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between; }

            &__title {
                font-size: 2.5em;
                font-weight: bold;
                letter-spacing: 2px;
                margin-right: 0.5em; }

            &__pulse {
                margin-right: -2em;
                height: 3em; }

            &__dash {
                height: 1px;
                width: 2em;
                background: white;
                margin: 0.5em 0; }

            &__subtitle {}

            &__dots {
                $dotSize: 3px;
                $dotMargin: 3px;

                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                margin: -3px;
                margin-top: auto;
                width: 3 * $dotSize + 6 * $dotMargin;

                @include media('<=mobile') {
                    display: none; }

                >* {
                    height: $dotSize;
                    width: $dotSize;
                    background: white;
                    border-radius: 50%;
                    margin: $dotMargin; } } }

        &__right {
            flex: 1;
            display: flex;
            flex-direction: column;
            padding: 4em 3em;
            background: var(--background-color);

            &__title {
                font-weight: bold;
                font-size: 1.6em;
                color: var(--foreground-color);
                margin-bottom: 1em; }

            &__subtitle {
                font-size: 0.8em;
                color: var(--faded-color);
                margin-bottom: 2em; }

            &__form {

                &__field {
                    position: relative;
                    height: 3em;
                    width: 100%;

                    &__icon {
                        position: absolute;
                        left: 0.5em;
                        z-index: 3;
                        height: 100%;
                        color: var(--xx-faded-color);
                        margin-left: 1em; }

                    &__input {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        z-index: 2;
                        padding: 0.8em;
                        padding-left: 3em;
                        width: 100%;
                        border: 0;
                        outline: none;
                        border: 1px solid var(--xx-faded-color);
                        border-radius: 16px;
                        transition: all 0.2s ease;

                        &::placeholder {
                            color: var(--xx-faded-color); }

                        &:focus {
                            box-shadow: 0 0 10px #00000022; } } }

                &__misc {
                    align-self: stretch;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 0.9em;

                    &__remember {
                        user-select: none; }

                    &__forgot {
                        cursor: pointer;
                        user-select: none; } }

                &__submit-btn {
                    width: 100%;
                    font-size: 1.2em;
                    margin-top: 1em;
                    border: 0;
                    border-radius: 1.5em;
                    padding: 1em 2em;
                    background: linear-gradient(135deg, var(--login-bg-high), var(--login-bg-low));
                    color: var(--bg-alt);
                    cursor: pointer;
                    transition: all 0.2s ease;

                    >*:not(:first-child) {
                        margin-left: 0.4em; }

                    &:hover {
                        box-shadow: 0 0 10px var(--primary-color); }

                    &:disabled {
                        filter: saturate(0.2);
                        cursor: not-allowed; } } } }

        .logo {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: var(--primary-color);

            &__image {
                height: 30px;
                width: 30px;
                margin-right: 10px; }

            &__text {
                font-weight: bold; } } }

    .login-form {
        display: flex;
        flex-direction: column;
        align-items: center;

        >* {
            margin-bottom: 10px; } } }



.spin {
    animation: 0.5s linear infinite spin; }

@keyframes spin {
    0% {
        transform: rotateZ(0deg); }
    100% {
        transform: rotateZ(-360deg); } }

@keyframes animate_gradient {
    0% {
        background-position: 0% 50%; }
    50% {
        background-position: 100% 50%; }
    100% {
        background-position: 0% 50%; } }

