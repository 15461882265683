.code-event {

    &__coding {
        display: flex;
        flex-direction: column;
        align-items: stretch; }

    &__cancel {
        display: flex;
        flex-direction: column;
        align-items: stretch;

        >*:not(:last-child) {
            margin-bottom: 0.5em; } }

    &__tag-list {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        margin: 0 -0.5em;

        >* {
            margin: 0.5em; }

        &__empty-message {
            color: var(--faded-color);
            font-size: 0.9em;
            text-align: center; } }

    &__tag-selectable {
        cursor: pointer;
        transition: all 0.3s ease;

        &--disabled {
            cursor: not-allowed;
            filter: saturate(0.2); } } }
