@import '/src/styles/_media.sass';

.table-full-paginator {

    &__results-count {
        color: var(--faded-color);
        font-size: 0.9em;
        margin-top: 0.3em; }

    .controls {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        margin-top: 20px;

        .pg-btn {
            padding: 0.3em 0.6em;
            outline: none;
            border: 0;
            border-radius: var(--border-radius-sm);
            background: var(--primary-color-alt);
            margin: 0 3px;
            cursor: pointer;
            font-weight: normal;

            &--active {
                color: var(--bg-alt);
                background-color: var(--primary-color); }

            &:disabled {
                cursor: not-allowed;
                background: var(--super-faded-color); } }

        .pg-page-selector {
            margin-left: 10px; }

        .shift-left {
            animation: shift-left linear 0.2s infinite alternate-reverse; }
        .shift-right {
            animation: shift-right linear 0.2s infinite alternate-reverse; } } }


@keyframes shift-left {
    0% {
        transform: translateX(0px); }
    100% {
        transform: translateX(-5px); } }

@keyframes shift-right {
    0% {
        transform: translateX(0px); }
    100% {
        transform: translateX(5px); } }
